import { LOCAL_STORAGE_KEYS } from './constants';
import { isDomAvailable } from 'utils/ssr';
import { safeLocalStorage } from './browser';
import { ErrorEvent } from '@sentry/types';
import type { User } from '@sentry/browser';

const RAILS_ENV = process.env.RAILS_ENV as string;
const RAILS_APP_HOST = process.env.RAILS_APP_HOST as string;

const sendIn = ['production', 'staging'];

const beforeSend = (event: ErrorEvent) => {
  if (sendIn.indexOf(RAILS_ENV) < 0) {
    return null;
  }
  return event;
};

let allowUrls = [
  'app.sessionlab.com',
  'staging.sessionlab.com',
  'shared.sessionlab.com',
  'cdn.sessionlab.com',
  'www.sessionlab.com',
];

if (RAILS_ENV === 'staging') {
  allowUrls.push(RAILS_APP_HOST);
}

const options = {
  dsn: process.env.SENTRY_DSN,
  environment: RAILS_ENV,
  release: process.env.GIT_REVISION,
  beforeSend,
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    '__gCrWeb', // http://stackoverflow.com/questions/26483541/referenceerror-cant-find-variable-gcrweb
  ],
  allowUrls,
};

type SentryType = typeof import('@sentry/browser');

let Sentry: Record<string, any> = {};

let methodsToStub = [
  'withScope',
  'captureException',
  'addBreadcrumb',
  'getCurrentScope',
];

methodsToStub.forEach(method => {
  Sentry[method] = (
    ...args: any
  ): void | { setUser: (user: User | null) => void } => {
    if (method === 'getCurrentScope') {
      return {
        setUser: (user: User | null) => {
          sentryQueue.push({ method: 'setUser', args: user });
        },
      };
    } else {
      sentryQueue.push({ method, args });
    }
  };
});

export class MissingTranslationError extends Error {
  constructor(message: string | undefined) {
    super(message);
    this.name = 'MissingTranslationError'; // needed instead of this.constructor.name because minification could change the name of the class
  }
}

const TRANSLATION_KEY_REGEX = /message:\s"(.*)",/g;

/**
 * Get list with warned translation keys
 * If it doesn't exist yet in local storage, return an empty one
 */
export const getTranslationKeyWarningListWithFallback = (): string[] => {
  if (isDomAvailable) {
    const warningListString = safeLocalStorage().getItem(
      LOCAL_STORAGE_KEYS.MISSING_TRANSLATION_WARNINGS,
    );
    if (warningListString) {
      return JSON.parse(warningListString);
    }
  }
  return [];
};

/**
 * Checks if a given translation key already exists in the warned list
 */
export const checkForMissingTranslationInLocalStorage = (
  missingKey: string,
) => {
  const warningList = getTranslationKeyWarningListWithFallback();
  return warningList.includes(missingKey);
};

/**
 * Writes a given translation key into local storage
 * If list does not exist, create it
 */
export const saveMissingKeyInLocalStorage = (missingKey: string) => {
  if (isDomAvailable) {
    const warningList = getTranslationKeyWarningListWithFallback();
    warningList.push(missingKey);
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.MISSING_TRANSLATION_WARNINGS,
      JSON.stringify(warningList),
    );
  }
};

/**
 * Retrieve an error message from react-intl and raise it on sentry
 * Currently error messages are raised as:
 * [React Intl] Cannot format message: "en.lock_popover.remove", using message id as fallback.
 */
export const handleTranslationError = (errorMessage: string) => {
  if (process.env.NODE_ENV === 'development') {
    console.error(errorMessage);
  }
  const regexResults = TRANSLATION_KEY_REGEX.exec(errorMessage);
  if (regexResults && regexResults.length === 2) {
    const missingKey = regexResults[1];
    if (!checkForMissingTranslationInLocalStorage(missingKey)) {
      (Sentry as SentryType).withScope(() => {
        (Sentry as SentryType).captureException(
          new MissingTranslationError(errorMessage),
        );
      });
      saveMissingKeyInLocalStorage(missingKey);
    }
  }
  TRANSLATION_KEY_REGEX.lastIndex = 0;
};

const sentryQueue: { method: string; args: any }[] = [];

const executeSentryQueue = () => {
  let item = sentryQueue.shift();
  while (item) {
    const { method, args } = item;
    if (method === 'setUser') {
      Sentry.getCurrentScope().setUser(args);
    } else {
      Sentry[method](...args);
    }
    item = sentryQueue.shift();
  }
};

const loadSentry = async () => {
  if (RAILS_ENV !== 'development') {
    const loadedSentry = await import('@sentry/browser');
    loadedSentry.init({
      ...options,
      integrations: integrations => {
        if (RAILS_ENV !== 'production') {
          integrations = integrations.filter(
            integration => integration.name !== 'Breadcrumbs',
          );
        }
        return [...integrations, loadedSentry.dedupeIntegration()];
      },
    });
    Sentry.captureException = loadedSentry.captureException;
    Sentry.withScope = loadedSentry.withScope;
    Sentry.addBreadcrumb = loadedSentry.addBreadcrumb;
    Sentry.getCurrentScope = loadedSentry.getCurrentScope;
    executeSentryQueue();
  }
};

if (isDomAvailable) {
  setTimeout(
    loadSentry,

    process.env.NODE_ENV === 'test' ? 0 : 10000,
  );
} else {
  loadSentry();
}

// Rest of the code remains the same

export default Sentry as SentryType;
