import { isDomAvailable } from 'utils/ssr';

/**
 * Code from https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill
 */
(function () {
  if (isDomAvailable && typeof window.CustomEvent === 'function') return false;

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail,
    );
    return evt;
  }

  if (isDomAvailable) window.CustomEvent = CustomEvent;
})();

/**
 * Code from https://developer.mozilla.org/en-US/docs/Web/API/Element/matches#Polyfill
 */
if (typeof Element !== 'undefined' && !Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;
}

/**
 * Code from https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach#Polyfill
 */
if (isDomAvailable && window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
}
if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
}
